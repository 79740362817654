import logo from './logo.svg';
import AnimateText from "./AnimateText";
import React from "react";
import Home from "./Home";

function App() {
  return (
    <div className="App">
      {/*<AnimateText type={3} text={'Hello'}/>*/}
      <Home/>
    </div>
  );
}

export default App;
